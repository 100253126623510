// ** typedjson imports
import { jsonObject, jsonMember } from 'typedjson'

@jsonObject
export class ApiLogModel {
    @jsonMember(Number)
    public readonly id: number

    @jsonMember(Number)
    public readonly service: string

    @jsonMember(String)
    public readonly query: string

    @jsonMember(String)
    public readonly result: string | null

    @jsonMember(Number)
    public readonly status_code: number

    @jsonMember(Number)
    public readonly resopnse_time: number

    @jsonMember(String)
    public readonly description: string

    @jsonMember(String)
    public readonly created_at: string
}

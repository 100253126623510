// ** types imports
import { ErrorModel } from './types'

export enum ResponseCode {
    /****************************** PUBLIC ******************************/

    /**
     * @description Use it when user's request successfully processed
     */
    REQUEST_PROCESSED_SUCCESSFULLY = 1001,

    /**
     * @description Use it when user's request process fails
     */
    REQUEST_PROCESS_FAILED_ERR,

    /**
     * @description Use it when user not found in the `DB`
     */
    USER_NOT_FOUND_ERR,

    /**
     * @description Use it when user's jwt token is not verified
     */
    USER_JWT_VERIFICATION_FAILED_ERR,

    /**
     * @description Use it when user tries to use a service that requires the `unlimited` subscription and the user's subscription is not so
     */
    UNLIMITED_SUBSCRIPTION_REQUIRED_ERR,

    /**
     * @description Use it when a user has no enough credit to use a service
     */
    NOT_ENOUGH_CREDIT_ERR,

    /**
     * @description Use it when a user that has a free trial account trying to see more results in search engines
     */
    SEARCH_MORE_LIMITED_ERR,

    /**
     * @description Use it when user's authentication fails by any reason
     */
    USER_AUTHENTICATION_FAILED_ERR,

    /**
     * @description Use it when user has no permission to a service
     */
    PERMISSION_DENIED_ERR,

    /**
     * @description Use it when user's trial period has been finished
     */
    TRIAL_PERIOD_FINISHED_ERR,

    /**
     * @description Use it when user's account has been suspended
     */
    USER_SUSPENDED_ERR,

    /**
     * @description Use it when user's ip has been blocked
     */
    USER_IP_BLOCKED_ERR,

    /**
     * @description Use it when it's necessary to upgrade it's plan
     */
    UPGRADE_PLAN_REQUIRED_ERR,

    /**
     * @description Use it when user sends too many requests
     */
    RATE_LIMIT_EXCEEDED_ERR,

    /****************************** AUTHENTICATION ******************************/
    /**
     * @description Use it when user signing in successfully
     */
    USER_SIGNED_IN = 1101,

    /**
     * @description Use it when user signing up successfully (first step)
     */
    USER_SIGNED_UP,

    /**
     * @description Use it when user sign up confirmed successfully (second step)
     */
    USER_SIGN_UP_CONFIRMED,

    /**
     * @description Use it when forget password code sent
     */
    USER_FORGET_PASSWORD_CODE_SENT,

    /**
     * @description Use it when user verify email code sent in forget password
     */
    USER_FORGET_PASSWORD_CODE_VERIFIED,

    /**
     * @description Use it when user changed password successfully
     */
    USER_PASSWORD_CHANGED,

    /**
     * @description Use it when
     */
    RECAPTCHA_VERIFIED,

    /**
     * @description Use it when user's email is verified
     */
    USER_EMAIL_VERIFIED,

    /**
     * @description User sign up
     */
    RECAPTCHA_VERIFICATION_FAILED_ERR,

    /**
     * @description Use it to
     */
    WRONG_CREDENTIALS_ERR,

    /**
     * @description Use it to remove user sign in failed
     */
    USER_SIGN_IN_FAILED_ERR,

    /**
     * @description Use it when user sends a wrong verification code in authentication processes
     */
    WRONG_VERIFICATION_CODE_ERR,

    /**
     * @description Use it when user's email not verified
     */
    USER_EMAIL_NOT_VERIFIED_ERR,

    /**
     * @description Use it when a user that is trying to sign up is sending a password with lower than 8 characters length
     */
    USER_PASSWORD_LENGTH_ERR,

    /**
     * @description Use it when a user wants to sign into an account that another one is signed in
     */
    USER_SIGN_IN_ONLINE_SESSION_ERR,

    /**
     * @description Use it when a verified user tries to sign up again
     */
    USER_SIGN_UP_DUPLICATE_VERIFY_ERR,

    /**
     * @description Use it when a user tries to sign up with a fake email
     */
    USER_SIGN_UP_UNSUPPORTED_EMAIL_PROVIDER_ERR,

    /**
     * @description Use it when user sends a wrong verification code in sign up confirmation process
     */
    WORNG_SIGN_UP_CONFIRM_CODE_ERR,

    /**
     * @description Use it when user sends an expired verification code in sign up confirmation process
     */
    EXPIRED_SIGN_UP_CONFIRM_CODE_ERR,

    /**
     * @description Use it when user enters wrong password
     */
    WRONG_PASSWORD_ERR,

    /**
     * @description Use it when user enters an invalid linkedin csrf code in linkedin oauth request
     */
    OAUTH_LINKEDIN_CSRF_CODE_INVALID,

    /**
     * @description Use it when user enters an expired linkedin csrf code in linkedin oauth request
     */
    OAUTH_LINKEDIN_CSRF_CODE_EXPIRED,

    /****************************** PLANS ******************************/
    /**
     * @description Use it when a team member (that is not the team head) tries to cancel it's plans
     */
    TEAM_MEMBER_CANCEL_SUBSCRIPTION_FAILED = 1150,

    /**
     * @description Use it when the subscription is paused before
     */
    SUBSCRIPTION_IS_PAUSED_ERR,

    /**
     * @description Use it when a user tries to downgrade it's free plan
     */
    FREE_SUBSCRIPTION_DOWNGRADE_ERR,

    /**
     * @description Use it when a plan is looking for, is not found
     */
    PLAN_NOT_FOUND_ERR,

    /**
     * @description Use it when a plan item is looking for, is not found
     */
    PLAN_ITEM_NOT_FOUND_ERR,

    /****************************** TICKETS ******************************/

    /**
     * @description Use it when user's ticket submits successfully
     */
    NEW_TICKET_SUBMITTED_SUCCESSFULLY = 1200,

    /**
     * @description Use it when all user
     */
    TICKET_REPLY_SUBMITTED_SUCCESSFULLY,

    /**
     * @description Use to when a ticket not found
     */
    TICKET_NOT_FOUND,

    /****************************** TEAMS ******************************/

    /**
     * @description Use it when a team invitation accepted by the invited user
     */
    TEAM_INVITATION_ACCEPTED = 1250,

    /**
     * @description Use it when a team member rejects a team invitation
     */
    TEAM_INVITATION_REJECTED,

    /**
     * @description Use it when team not found
     */
    TEAM_NOT_FOUND,

    /**
     * @description Use it when team member not found
     */
    TEAM_MEMBER_NOT_FOUND,

    /**
     * @description Use it when team invitation not found
     */
    TEAM_INVITATION_NOT_FOUND,

    /**
     * @description Use it when user tries to add itself to it's team !
     */
    TEAM_HEAD_SELF_INVITATION_ERR,

    /**
     * @description Use it when an `active` team member tries to invite anyone
     */
    TEAM_MEMBER_INVITE_ERR,

    /**
     * @description Use it when a team head tries to invite a suspended user
     */
    INVITE_SUSPENDED_USER_ERR,

    /**
     * @description Use it when a team head tries to invite another team's member
     */
    INVITE_A_TEAM_MEMBER_ERR,

    /**
     * @description Use it when the calling user has no team
     */
    USER_NO_TEAM_ERR,

    /**
     * @description Use it when team head tries to remove itself from the team
     */
    TEAM_HEAD_SELF_REMOVE_ERR,

    /**
     * @description Use it when invited user has an active subscription
     */
    INVITE_ACTIVE_MEMBER_ERR,

    /****************************** LISTS ******************************/

    /**
     * @description Use it when an item successfully adding to a list
     */
    LIST_ITEM_ADDED = 1300,

    /**
     * @description Use it when a list created successfully
     */
    LIST_CREATED,

    /**
     * @description Use it when list deletion successfully done
     */
    LIST_ITEM_DELETED,

    /**
     * @description Use it when list not found
     */
    LIST_NOT_FOUND_ERR,

    /**
     * @description Use it when an item is not exist in the target list
     */
    LIST_ITEM_MATCH_ERR,

    /**
     * @description Use it when list item that user is looking for not found
     */
    LIST_ITEM_NOT_FOUND_ERR,

    /**
     * @description Use it when user tries to add an existing list item
     */
    ADD_LIST_ITEM_DUPLICATE_ERR,

    /**
     * @description Use it when list
     */
    LIST_TYPE_NOT_VALID_ERR,

    /**
     * @description Use it when user tries to remove a default list
     */
    DEFAULT_LIST_DELETION_ERR,

    /**
     * @description Use it when a user tries to rename a deleted list
     */
    DEFAULT_LIST_RENAME_ERR,

    /**
     * @description Use it when user tries to delet a deleted list
     */
    DELETED_LIST_DELETION_ERR,

    /**
     * @description Use it when list is empty
     */
    LIST_EMPTY_ERR,

    /**
     * @description Use it when user tries to rename (or any action) a list that is deleted
     */
    LIST_NOT_ACTIVE_ERR,

    /****************************** SAVED SEARCHES & RECENT SEARCHES ******************************/
    /**
     * @description Use it when a saved search successfully saved
     */
    SAVED_SEARCH_SAVED = 1400,

    /**
     * @description Use it when a saved search successfully deleted
     */
    SAVED_SEARCH_DELETED,

    /**
     * @description Use it when a saved search not found in the `DB`
     */
    SAVED_SEARCH_NOT_FOUND_ERR,

    /**
     * @description Use it when a recent search not found in the `DB`
     */
    RECENT_SEARCH_SAVED,

    /**
     * @description Use it when a recent search not found in the `DB`
     */
    RECENT_SEARCH_NOT_FOUND_ERR,

    /****************************** ENRICHMENT ENGINE ******************************/
    /**
     * @description Use it when no bulk service matched
     */
    NO_MATCHED_BULK_SERVICE = 1450,

    /**
     * @description Use it when free user tries to run free bulk
     */
    RUN_BULK_LIMITATION_ERR,

    /**
     * @description Use it when bulk not found
     */
    BULK_NOT_FOUND,

    /****************************** INTEGRATIONS ******************************/
    /**
     * @description Use it when user is not connected to it's hubspot crm for integration usages
     */
    HUBSPOT_NOT_CONNECTED_ERR = 1500,

    /**
     * @description Use it when user's hubspot connection enabled
     */
    HUBSPOT_CONNECTION_ENABLED,

    /**
     * @description Use it when user's hubspot connection disabled
     */
    HUBSPOT_CONNECTION_DISABLED,

    /**
     * @description Use it when user is not connected to it's zoho crm for integration usages
     */
    ZOHO_NOT_CONNECTED_ERR,

    /**
     * @description Use it when user's zoho connection enabled
     */
    ZOHO_CONNECTION_ENABLED,

    /**
     * @description Use it when user's zoho connection disabled
     */
    ZOHO_CONNECTION_DISABLED,

    /**
     * @description Use it when user is not connected to it's salesforce crm for integration usages
     */
    SALESFORCE_NOT_CONNECTED_ERR,

    /**
     * @description Use it when user's salesforce connection enabled
     */
    SALESFORCE_CONNECTION_ENABLED,

    /**
     * @description Use it when user's salesforce connection disabled
     */
    SALESFORCE_CONNECTION_DISABLED,

    /****************************** BUSINESS DIRECTORY ******************************/

    /**
     * @description Use it when an order created successfully
     */
    NEW_ORDER_CREATED_SUCCESS = 1550,

    /**
     * @description Use it when an order item created successfully
     */
    NEW_ORDER_ITEM_CREATED_SUCCESS,

    /**
     * @description Use it when an order's status turn to done
     */
    ORDER_DONE_SUCCESS,

    /**
     * @description Use it when an order not found
     */
    ORDER_NOT_FOUND_ERR,

    /**
     * @description Use it when user tries to download an incomplete order
     */
    ORDER_INCOMPLETE_ERR,

    /**
     * @description Use it when an order item not found
     */
    ORDER_ITEM_NOT_FOUND_ERR,

    /****************************** CAMPAIGNS ******************************/

    /**
     * @description Use it when a campaign's creation done successfully
     */
    CAMPAIGN_CREATED_SUCCESS = 1600,

    /**
     * @description Use it when a campaign's email account creation done successfully
     */
    CAMPAIGN_EMAIL_ACCOUNT_CREATED_SUCCESS,

    /**
     * @description Use it when a campaign doesn't exists (not found)
     */
    CAMPAIGN_NOT_FOUND_ERR,

    /**
     * @description Use it when a doesn't exist
     */
    CAMPAIGN_LEAD_NOT_FOUND_ERR,

    /**
     * @description Use it when user tries to add a lead that has no valid email to it's campaign leads
     */
    CAMPAIGN_LEAD_NO_VALID_EMAIL_ERR,

    /**
     * @description Use it when a lead exists already
     */
    CAMPAIGN_LEAD_DUPLICATE_ERR,

    /**
     * @description Use it when an email account doesn't exist
     */
    CAMPAIGN_EMAIL_ACCOUNT_NOT_FOUND_ERR,

    /**
     * @description Use it when an email account exists already
     */
    CAMPAIGN_EMAIL_ACCOUNT_DUPLICATE_ERR,

    /****************************** STRIPE ******************************/

    /**
     * @description Use it when a checkout's `payment_status` is `paid`
     */
    STRIPE_CHECKOUT_PAYMENT_SUCCESS = 1650,

    /**
     * @description Use it when a checkout's `payment_status` is not `paid`
     */
    STRIPE_CHECKOUT_PAYMENT_INCOMPLETE_ERR

    /****************************** INTERCOM ******************************/
}

type CodeMessageOptions = {
    noMatchedMessage?: boolean
    customUnknownErrMessage?: string
}

/**
 * Use to a response code message
 *
 * @param code `ResponseCode`
 */
export const getCodeMessage = (code: ResponseCode, params?: CodeMessageOptions): string => {
    const responseCodes: Array<ErrorModel> = [
        { code: ResponseCode.REQUEST_PROCESSED_SUCCESSFULLY, message: 'Request successfully processed' },
        { code: ResponseCode.REQUEST_PROCESS_FAILED_ERR, message: 'Unable to process your request' },
        { code: ResponseCode.USER_NOT_FOUND_ERR, message: 'User not found' },
        {
            code: ResponseCode.SEARCH_MORE_LIMITED_ERR,
            message: 'You reached your search limits. Please upgrade your plan to see more results'
        },
        { code: ResponseCode.USER_JWT_VERIFICATION_FAILED_ERR, message: "You're not authorized" },
        {
            code: ResponseCode.UNLIMITED_SUBSCRIPTION_REQUIRED_ERR,
            message: 'You should buy unlimited plan to access the section'
        },
        { code: ResponseCode.NOT_ENOUGH_CREDIT_ERR, message: "You've not enough credit" },
        { code: ResponseCode.USER_AUTHENTICATION_FAILED_ERR, message: "You're not authorized" },
        { code: ResponseCode.PERMISSION_DENIED_ERR, message: "You've not permission" },
        { code: ResponseCode.TRIAL_PERIOD_FINISHED_ERR, message: 'Your trial period finished up' },
        { code: ResponseCode.USER_SUSPENDED_ERR, message: "You're suspended" },
        { code: ResponseCode.USER_IP_BLOCKED_ERR, message: 'Your IP has been restricted' },
        {
            code: ResponseCode.UPGRADE_PLAN_REQUIRED_ERR,
            message: "You can't access to the section's result. Please upgrade your plan first."
        },
        {
            code: ResponseCode.RATE_LIMIT_EXCEEDED_ERR,
            message: 'You sent too many requests. Please try again later'
        },
        { code: ResponseCode.USER_SIGNED_IN, message: "You're signed in successfully" },
        { code: ResponseCode.USER_SIGNED_UP, message: "You're signed up successfully" },
        { code: ResponseCode.USER_SIGN_UP_CONFIRMED, message: 'Your sign up confirmed successfully' },
        { code: ResponseCode.USER_FORGET_PASSWORD_CODE_SENT, message: 'Verification code sent to your email' },
        { code: ResponseCode.USER_FORGET_PASSWORD_CODE_VERIFIED, message: 'Verification code successfully verified' },
        { code: ResponseCode.USER_PASSWORD_CHANGED, message: 'Your password updated successfully' },
        { code: ResponseCode.RECAPTCHA_VERIFIED, message: 'Recaptcha Verified' },
        { code: ResponseCode.USER_EMAIL_VERIFIED, message: 'Email Verified' },
        { code: ResponseCode.RECAPTCHA_VERIFICATION_FAILED_ERR, message: 'Please refresh your page' },
        { code: ResponseCode.WRONG_CREDENTIALS_ERR, message: 'Wrong credentials' },
        { code: ResponseCode.USER_SIGN_IN_FAILED_ERR, message: 'Sign in failed' },
        { code: ResponseCode.WRONG_VERIFICATION_CODE_ERR, message: 'Wrong verification code' },
        { code: ResponseCode.USER_EMAIL_NOT_VERIFIED_ERR, message: 'Your email is not verified' },
        { code: ResponseCode.USER_PASSWORD_LENGTH_ERR, message: 'Password length must be atleast 8 characters' },
        {
            code: ResponseCode.USER_SIGN_UP_DUPLICATE_VERIFY_ERR,
            message: "You're signed up before. Please sign in to your account."
        },
        { code: ResponseCode.USER_SIGN_UP_UNSUPPORTED_EMAIL_PROVIDER_ERR, message: 'Unsupported email provider' },
        { code: ResponseCode.WORNG_SIGN_UP_CONFIRM_CODE_ERR, message: 'Wrong verification code' },
        {
            code: ResponseCode.EXPIRED_SIGN_UP_CONFIRM_CODE_ERR,
            message: 'Verification code expired. A new code sent to your email.'
        },
        { code: ResponseCode.WRONG_PASSWORD_ERR, message: 'Wrong password' },
        { code: ResponseCode.OAUTH_LINKEDIN_CSRF_CODE_INVALID, message: 'Linkedin verification failed' },
        {
            code: ResponseCode.OAUTH_LINKEDIN_CSRF_CODE_EXPIRED,
            message: 'Your linkedin verification code is expired, please try again'
        },
        {
            code: ResponseCode.TEAM_MEMBER_CANCEL_SUBSCRIPTION_FAILED,
            message: "A team member can't cancel it's subscription"
        },
        {
            code: ResponseCode.SUBSCRIPTION_IS_PAUSED_ERR,
            message: 'Subscription is already paused'
        },
        { code: ResponseCode.FREE_SUBSCRIPTION_DOWNGRADE_ERR, message: "You can't downgrade your free subscription" },
        { code: ResponseCode.PLAN_NOT_FOUND_ERR, message: 'Plan not found' },
        { code: ResponseCode.NEW_TICKET_SUBMITTED_SUCCESSFULLY, message: 'Your ticket submitted successfully' },
        { code: ResponseCode.TICKET_REPLY_SUBMITTED_SUCCESSFULLY, message: 'Your ticket reply submitted successfully' },
        { code: ResponseCode.TICKET_NOT_FOUND, message: 'Ticket not found' },
        { code: ResponseCode.TEAM_INVITATION_ACCEPTED, message: 'Team invitation accepted' },
        { code: ResponseCode.TEAM_INVITATION_REJECTED, message: 'Team invitation rejected' },
        { code: ResponseCode.TEAM_NOT_FOUND, message: 'Team not found' },
        { code: ResponseCode.TEAM_MEMBER_NOT_FOUND, message: 'Team member not found' },
        { code: ResponseCode.TEAM_INVITATION_NOT_FOUND, message: 'No such team invitation' },
        { code: ResponseCode.TEAM_HEAD_SELF_INVITATION_ERR, message: "You can't invite yourself to your team" },
        { code: ResponseCode.TEAM_MEMBER_INVITE_ERR, message: 'Invite team member failed' },
        { code: ResponseCode.INVITE_SUSPENDED_USER_ERR, message: "You can't invite a suspended user" },
        { code: ResponseCode.INVITE_A_TEAM_MEMBER_ERR, message: 'The invited user has a team' },
        { code: ResponseCode.USER_NO_TEAM_ERR, message: 'This user is not a team member' },
        { code: ResponseCode.TEAM_HEAD_SELF_REMOVE_ERR, message: "You can't remove yourself from your team" },
        { code: ResponseCode.INVITE_ACTIVE_MEMBER_ERR, message: 'Invited user has an active subscription' },
        { code: ResponseCode.LIST_ITEM_ADDED, message: 'List item added successfully' },
        { code: ResponseCode.LIST_CREATED, message: 'List created successfully' },
        { code: ResponseCode.LIST_ITEM_DELETED, message: 'List item deleted successfully' },
        { code: ResponseCode.LIST_NOT_FOUND_ERR, message: 'No such list' },
        { code: ResponseCode.LIST_ITEM_MATCH_ERR, message: 'List item not mathced' },
        { code: ResponseCode.LIST_ITEM_NOT_FOUND_ERR, message: 'List item not found' },
        { code: ResponseCode.ADD_LIST_ITEM_DUPLICATE_ERR, message: 'The list item exists' },
        { code: ResponseCode.LIST_TYPE_NOT_VALID_ERR, message: 'List type is not valid' },
        { code: ResponseCode.DEFAULT_LIST_DELETION_ERR, message: "You can't delete a default list" },
        { code: ResponseCode.DEFAULT_LIST_RENAME_ERR, message: "You can't rename a default list" },
        { code: ResponseCode.DELETED_LIST_DELETION_ERR, message: 'The list is deleted' },
        { code: ResponseCode.LIST_EMPTY_ERR, message: 'List is empty' },
        { code: ResponseCode.LIST_NOT_ACTIVE_ERR, message: 'The list is not active' },
        { code: ResponseCode.SAVED_SEARCH_SAVED, message: 'Saved search saved successfully' },
        { code: ResponseCode.SAVED_SEARCH_DELETED, message: 'Saved search deleted successfully' },
        { code: ResponseCode.SAVED_SEARCH_NOT_FOUND_ERR, message: 'Saved search not found' },
        { code: ResponseCode.RECENT_SEARCH_SAVED, message: 'Search saved successfully' },
        { code: ResponseCode.RECENT_SEARCH_NOT_FOUND_ERR, message: 'Recent search not found' },
        { code: ResponseCode.NO_MATCHED_BULK_SERVICE, message: 'No matched service' },
        {
            code: ResponseCode.RUN_BULK_LIMITATION_ERR,
            message: "Free users can't use free services more than 200 credits every day"
        },
        { code: ResponseCode.BULK_NOT_FOUND, message: 'Bulk not found' },
        {
            code: ResponseCode.HUBSPOT_NOT_CONNECTED_ERR,
            message: 'Plesae connect your hubspot integration first'
        },
        {
            code: ResponseCode.HUBSPOT_CONNECTION_ENABLED,
            message: 'Hubspot integration is connected'
        },
        {
            code: ResponseCode.HUBSPOT_CONNECTION_DISABLED,
            message: 'Hubspot integration is not connected'
        },
        {
            code: ResponseCode.ZOHO_NOT_CONNECTED_ERR,
            message: 'Plesae connect your zoho integration first'
        },
        {
            code: ResponseCode.ZOHO_CONNECTION_ENABLED,
            message: 'Zoho integration is connected'
        },
        {
            code: ResponseCode.ZOHO_CONNECTION_DISABLED,
            message: 'Zoho integration is not connected'
        },
        {
            code: ResponseCode.SALESFORCE_NOT_CONNECTED_ERR,
            message: 'Plesae connect your salesforce integration first'
        },
        {
            code: ResponseCode.SALESFORCE_CONNECTION_ENABLED,
            message: 'Salesforce integration is connected'
        },
        {
            code: ResponseCode.SALESFORCE_CONNECTION_DISABLED,
            message: 'Salesforce integration is not connected'
        },
        {
            code: ResponseCode.NEW_ORDER_CREATED_SUCCESS,
            message: 'New order created successfully'
        },
        {
            code: ResponseCode.ORDER_DONE_SUCCESS,
            message: 'Order completed'
        },
        {
            code: ResponseCode.ORDER_NOT_FOUND_ERR,
            message: 'No such order'
        },
        {
            code: ResponseCode.ORDER_INCOMPLETE_ERR,
            message: 'The order is not ready yet'
        },
        {
            code: ResponseCode.CAMPAIGN_CREATED_SUCCESS,
            message: 'Campaign created successfully'
        },
        {
            code: ResponseCode.CAMPAIGN_EMAIL_ACCOUNT_CREATED_SUCCESS,
            message: 'Email account created successfully'
        },
        {
            code: ResponseCode.CAMPAIGN_NOT_FOUND_ERR,
            message: 'No such campaign'
        },
        {
            code: ResponseCode.CAMPAIGN_LEAD_NOT_FOUND_ERR,
            message: 'No such lead'
        },
        {
            code: ResponseCode.CAMPAIGN_LEAD_NO_VALID_EMAIL_ERR,
            message: 'No such lead'
        },
        {
            code: ResponseCode.CAMPAIGN_LEAD_DUPLICATE_ERR,
            message: 'This lead exists'
        },
        {
            code: ResponseCode.CAMPAIGN_EMAIL_ACCOUNT_NOT_FOUND_ERR,
            message: 'No such email account'
        },
        {
            code: ResponseCode.CAMPAIGN_EMAIL_ACCOUNT_DUPLICATE_ERR,
            message: 'This email account exists'
        }
    ]

    const responseMsg = responseCodes.filter((res) => res.code === code).at(0)?.message
    const defaultUnknownErrMsg = 'Unknown ERR'
    const unknownErrMsg = params?.customUnknownErrMessage || defaultUnknownErrMsg

    return responseMsg || (params?.noMatchedMessage ? unknownErrMsg : defaultUnknownErrMsg) || defaultUnknownErrMsg
}

// ** react imports
import { Suspense, lazy } from 'react'

// ** react router imports
import { RouteObject } from 'react-router-dom'

// ** layouts imports
import AclGuard from 'src/@core/components/auth/AclGuard'

// ** custom components imports
import Spinner from 'src/@core/components/spinner'
import { CampaignProvider } from 'src/@core/context/campaignContext'
import DefaultScreen from 'src/pages/default-screen'

// ** views imports
// academic engine
const ProfessorSearchScreen = lazy(() => import('src/pages/academic/professor/search'))
const ProfessorInfoScreen = lazy(() => import('src/pages/academic/professor/info'))
const UniversitySearchScreen = lazy(() => import('src/pages/academic/university/search'))
const UniversityInfoScreen = lazy(() => import('src/pages/academic/university/info'))

// credit usage
const CreditUsageScreen = lazy(() => import('src/pages/credits-usage'))

// prospect engine
const ContactSearchScreen = lazy(() => import('src/pages/prospect/contact/search'))
const ContactInfoScreen = lazy(() => import('src/pages/prospect/contact/info'))
const CorporationInfoScreen = lazy(() => import('src/pages/prospect/contact/corporation'))
const CompanySearchScreen = lazy(() => import('src/pages/prospect/company/search'))
const CompanyInfoScreen = lazy(() => import('src/pages/prospect/company/info'))

// enrichment engine
const ServicesListScreen = lazy(() => import('src/pages/enrichment/services'))
const ServiceScreen = lazy(() => import('src/pages/enrichment/service'))
const BulksHistoryListScreen = lazy(() => import('src/pages/enrichment/history/list'))
const BulkInfoScreen = lazy(() => import('src/pages/enrichment/history/bulk'))

// hr engine
const HrEngineScreen = lazy(() => import('src/pages/hr/search'))

// business directory
const BusinessDirectorySearchScreen = lazy(() => import('src/pages/business-directory/search'))
const BusinessDirectoryOrdersScreen = lazy(() => import('src/pages/business-directory/orders'))
const OrderSuccessPaymentScreen = lazy(() => import('src/pages/business-directory/order/success_payment'))
const OrderFailedPaymentScreen = lazy(() => import('src/pages/business-directory/order/failed_payment'))

// integrations
const IntegrationsListScreen = lazy(() => import('src/pages/integrations/list'))
const ZapierIntegrationScreen = lazy(() => import('src/pages/integrations/zapier'))
const HubspotScreen = lazy(() => import('src/pages/integrations/hubspot/integration'))
const HubspotSuccessScreen = lazy(() => import('src/pages/integrations/hubspot/success'))
const HubspotFailedScreen = lazy(() => import('src/pages/integrations/hubspot/failed'))
const SalesforceScreen = lazy(() => import('src/pages/integrations/salesforce/integration'))
const SalesforceSuccessScreen = lazy(() => import('src/pages/integrations/salesforce/success'))
const ZohoScreen = lazy(() => import('src/pages/integrations/zoho/integration'))
const ZohoSuccessScreen = lazy(() => import('src/pages/integrations/zoho/success'))

// invoice
const InvoicesListScreen = lazy(() => import('src/pages/invoices/list'))
const InvoicesInfoScreen = lazy(() => import('src/pages/invoices/invoice'))

// lists
const ListsScreen = lazy(() => import('src/pages/lists/list'))
const ListInfoScreen = lazy(() => import('src/pages/lists/info'))

// plans
const LeadPlansScreen = lazy(() => import('src/pages/plans/lead-generation'))
const EnrichmentEngineScreen = lazy(() => import('src/pages/plans/enrichment-engine'))
const AcademicEngineScreen = lazy(() => import('src/pages/plans/academic-engine'))
const SuccessPaymentScreen = lazy(() => import('src/pages/plans/success-payment'))
const FailedPaymentScreen = lazy(() => import('src/pages/plans/failed-payment'))

// payments
const CreditsSucessPaymentScreen = lazy(() => import('src/pages/payment/success-payment'))
const CreditsFailedPaymentScreen = lazy(() => import('src/pages/payment/failed-payment'))

// teams
const TeamsListScreen = lazy(() => import('src/pages/teams/list'))
const TeamMemberInfoScreen = lazy(() => import('src/pages/teams/member'))

// campaigns
const CampaignsListScreen = lazy(() => import('src/pages/campaigns/list'))
const NewCampaignScreen = lazy(() => import('src/pages/campaigns/new'))
const CampaignInfoScreen = lazy(() => import('src/pages/campaigns/info'))
const CampaignsEmailsManagementScreen = lazy(() => import('src/pages/campaigns/emails/list'))
const NewCampaignEmailScreen = lazy(() => import('src/pages/campaigns/emails/new'))
const EditCampaignEmailScreen = lazy(() => import('src/pages/campaigns/emails/edit'))

// tickets
const TicketsListScreen = lazy(() => import('src/pages/tickets/list'))
const NewTicketScreen = lazy(() => import('src/pages/tickets/new'))

// profile
const ProfileScreen = lazy(() => import('src/pages/profile'))
const ApiManagementScreen = lazy(() => import('src/pages/api-management'))

const panelRoutes: Array<RouteObject> = [
    {
        id: 'default-screen',
        index: true,
        element: <DefaultScreen />
    },
    {
        id: 'academic-engine-group',
        path: 'academic',
        children: [
            {
                id: 'professor-search-screen',
                path: 'professor',
                element: (
                    <AclGuard aclAbilities={{ action: 'page-view', subject: 'professor-search' }}>
                        <Suspense fallback={<Spinner />}>
                            <ProfessorSearchScreen />
                        </Suspense>
                    </AclGuard>
                )
            },
            {
                id: 'professor-info-screen',
                path: 'professor/:id',
                element: (
                    <AclGuard aclAbilities={{ action: 'page-view', subject: 'professor-profile' }}>
                        <Suspense fallback={<Spinner />}>
                            <ProfessorInfoScreen />
                        </Suspense>
                    </AclGuard>
                )
            },
            {
                id: 'university-search-screen',
                path: 'university',
                element: (
                    <AclGuard aclAbilities={{ action: 'page-view', subject: 'university-search' }}>
                        <Suspense fallback={<Spinner />}>
                            <UniversitySearchScreen />
                        </Suspense>
                    </AclGuard>
                )
            },
            {
                id: 'university-info-screen',
                path: 'university/:id',
                element: (
                    <AclGuard aclAbilities={{ action: 'page-view', subject: 'university-profile' }}>
                        <Suspense fallback={<Spinner />}>
                            <UniversityInfoScreen />
                        </Suspense>
                    </AclGuard>
                )
            }
        ]
    },
    {
        id: 'credits-usage-screen',
        path: 'credits-usage',
        element: (
            <AclGuard aclAbilities={{ action: 'page-view', subject: 'credit-usage' }}>
                <Suspense fallback={<Spinner />}>
                    <CreditUsageScreen />
                </Suspense>
            </AclGuard>
        )
    },
    {
        id: 'enrichment-engine-group',
        path: 'enrichment',
        children: [
            {
                id: 'bulks-history-group',
                path: 'history',
                children: [
                    {
                        id: 'bulks-history-list-screen',
                        index: true,
                        element: (
                            <AclGuard aclAbilities={{ action: 'page-view', subject: 'services-history' }}>
                                <Suspense fallback={<Spinner />}>
                                    <BulksHistoryListScreen />
                                </Suspense>
                            </AclGuard>
                        )
                    },
                    {
                        id: 'bulk-result-screen',
                        path: ':id',
                        element: (
                            <AclGuard aclAbilities={{ action: 'page-view', subject: 'service-result' }}>
                                <Suspense fallback={<Spinner />}>
                                    <BulkInfoScreen />
                                </Suspense>
                            </AclGuard>
                        )
                    }
                ]
            },
            {
                id: 'enrichment-services-list',
                path: 'services',
                children: [
                    {
                        id: 'services-list-screen',
                        index: true,
                        element: (
                            <AclGuard aclAbilities={{ action: 'page-view', subject: 'services-list' }}>
                                <Suspense fallback={<Spinner />}>
                                    <ServicesListScreen />
                                </Suspense>
                            </AclGuard>
                        )
                    },
                    {
                        id: 'new-bulk-screen',
                        path: ':id',
                        element: (
                            <AclGuard aclAbilities={{ action: 'page-view', subject: 'new-bulk' }}>
                                <Suspense fallback={<Spinner />}>
                                    <ServiceScreen />
                                </Suspense>
                            </AclGuard>
                        )
                    }
                ]
            }
        ]
    },
    {
        id: 'hr-engine-screen',
        path: 'hr',
        element: (
            <AclGuard aclAbilities={{ action: 'page-view', subject: 'hr-engine' }}>
                <Suspense fallback={<Spinner />}>
                    <HrEngineScreen />
                </Suspense>
            </AclGuard>
        )
    },
    {
        id: 'business-directory-group',
        path: 'business-directory',
        children: [
            {
                path: 'search',
                element: (
                    <AclGuard aclAbilities={{ action: 'page-view', subject: 'business-directory' }}>
                        <Suspense fallback={<Spinner />}>
                            <BusinessDirectorySearchScreen />
                        </Suspense>
                    </AclGuard>
                )
            },
            {
                path: 'orders',
                element: (
                    <AclGuard aclAbilities={{ action: 'page-view', subject: 'business-directory-orders' }}>
                        <Suspense fallback={<Spinner />}>
                            <BusinessDirectoryOrdersScreen />
                        </Suspense>
                    </AclGuard>
                )
            },
            {
                path: 'order',
                children: [
                    {
                        path: 'success_payment',
                        element: (
                            <AclGuard aclAbilities={{ action: 'page-view', subject: 'b2b-success-payment' }}>
                                <Suspense fallback={<Spinner />}>
                                    <OrderSuccessPaymentScreen />
                                </Suspense>
                            </AclGuard>
                        )
                    },
                    {
                        path: 'failed_payment',
                        element: (
                            <AclGuard aclAbilities={{ action: 'page-view', subject: 'b2b-failed-payment' }}>
                                <Suspense fallback={<Spinner />}>
                                    <OrderFailedPaymentScreen />
                                </Suspense>
                            </AclGuard>
                        )
                    }
                ]
            }
        ]
    },
    {
        id: 'integrations-group',
        path: 'integrations',
        children: [
            {
                id: 'integrations-list-screen',
                index: true,
                element: (
                    <AclGuard aclAbilities={{ action: 'page-view', subject: 'integrations' }}>
                        <Suspense fallback={<Spinner />}>
                            <IntegrationsListScreen />
                        </Suspense>
                    </AclGuard>
                )
            },
            {
                id: 'zapier-integration-screen',
                path: 'zapier',
                element: (
                    <AclGuard aclAbilities={{ action: 'page-view', subject: 'zapier-integration' }}>
                        <Suspense fallback={<Spinner />}>
                            <ZapierIntegrationScreen />
                        </Suspense>
                    </AclGuard>
                )
            },
            {
                id: 'hubspot-integration-group',
                path: 'hubspot',
                children: [
                    {
                        id: 'hubspot-integration-screen',
                        index: true,
                        element: (
                            <AclGuard aclAbilities={{ action: 'page-view', subject: 'hubspot-integration' }}>
                                <Suspense fallback={<Spinner />}>
                                    <HubspotScreen />
                                </Suspense>
                            </AclGuard>
                        )
                    },
                    {
                        id: 'hubspot-success-connection-screen',
                        path: 'success_connection',
                        element: (
                            <AclGuard
                                aclAbilities={{
                                    action: 'page-view',
                                    subject: 'hubspot-integration-successful-connect'
                                }}
                            >
                                <Suspense fallback={<Spinner />}>
                                    <HubspotSuccessScreen />
                                </Suspense>
                            </AclGuard>
                        )
                    },
                    {
                        id: 'hubspot-failure-connection-screen',
                        path: 'failed_connection',
                        element: (
                            <AclGuard
                                aclAbilities={{ action: 'page-view', subject: 'hubspot-integration-failed-connect' }}
                            >
                                <Suspense fallback={<Spinner />}>
                                    <HubspotFailedScreen />
                                </Suspense>
                            </AclGuard>
                        )
                    }
                ]
            },
            {
                id: 'zoho-integration-group',
                path: 'zoho',
                children: [
                    {
                        id: 'zoho-integration-screen',
                        index: true,
                        element: (
                            <AclGuard aclAbilities={{ action: 'page-view', subject: 'zoho-integration' }}>
                                <Suspense fallback={<Spinner />}>
                                    <ZohoScreen />
                                </Suspense>
                            </AclGuard>
                        )
                    },
                    {
                        id: 'zoho-success-connection-screen',
                        path: 'success_connection',
                        element: (
                            <AclGuard
                                aclAbilities={{ action: 'page-view', subject: 'zoho-integration-successful-connect' }}
                            >
                                <Suspense fallback={<Spinner />}>
                                    <ZohoSuccessScreen />
                                </Suspense>
                            </AclGuard>
                        )
                    }
                ]
            },
            {
                path: 'salesforce',
                children: [
                    {
                        id: 'salesforce-integration-screen',
                        index: true,
                        element: (
                            <AclGuard aclAbilities={{ action: 'page-view', subject: 'salesforce-integration' }}>
                                <Suspense fallback={<Spinner />}>
                                    <SalesforceScreen />
                                </Suspense>
                            </AclGuard>
                        )
                    },
                    {
                        id: 'salesforce-success-connection-screen',
                        path: 'success_connection',
                        element: (
                            <AclGuard
                                aclAbilities={{
                                    action: 'page-view',
                                    subject: 'salesforce-integration-successful-connect'
                                }}
                            >
                                <Suspense fallback={<Spinner />}>
                                    <SalesforceSuccessScreen />
                                </Suspense>
                            </AclGuard>
                        )
                    }
                ]
            }
        ]
    },
    {
        id: 'invoices-group',
        path: 'invoices',
        children: [
            {
                id: 'invoices-list-screen',
                index: true,
                element: (
                    <AclGuard aclAbilities={{ action: 'page-view', subject: 'invoices' }}>
                        <Suspense fallback={<Spinner />}>
                            <InvoicesListScreen />
                        </Suspense>
                    </AclGuard>
                )
            },
            {
                id: 'invoice-screen',
                path: ':id',
                element: (
                    <AclGuard aclAbilities={{ action: 'page-view', subject: 'invoice-view' }}>
                        <Suspense fallback={<Spinner />}>
                            <InvoicesInfoScreen />
                        </Suspense>
                    </AclGuard>
                )
            }
        ]
    },
    {
        id: 'lists-group',
        path: 'lists',
        children: [
            {
                id: 'lists-screen',
                index: true,
                element: (
                    <AclGuard aclAbilities={{ action: 'page-view', subject: 'lists' }}>
                        <Suspense fallback={<Spinner />}>
                            <ListsScreen />
                        </Suspense>
                    </AclGuard>
                )
            },
            {
                id: 'list-screen',
                path: ':id',
                element: (
                    <AclGuard aclAbilities={{ action: 'page-view', subject: 'list-view' }}>
                        <Suspense fallback={<Spinner />}>
                            <ListInfoScreen />
                        </Suspense>
                    </AclGuard>
                )
            }
        ]
    },
    {
        id: 'plans-group',
        path: 'plans',
        children: [
            {
                id: 'enrichment-engine-plans-screen',
                path: 'enrichment-engine',
                element: (
                    <AclGuard aclAbilities={{ action: 'page-view', subject: 'enrichment-plans' }}>
                        <Suspense fallback={<Spinner />}>
                            <EnrichmentEngineScreen />
                        </Suspense>
                    </AclGuard>
                )
            },
            {
                id: 'lead-generation-plans-screen',
                path: 'lead-generation',
                element: (
                    <AclGuard aclAbilities={{ action: 'page-view', subject: 'lead-plans' }}>
                        <Suspense fallback={<Spinner />}>
                            <LeadPlansScreen />
                        </Suspense>
                    </AclGuard>
                )
            },
            {
                id: 'academic-generation-plans-screen',
                path: 'academic-engine',
                element: (
                    <AclGuard aclAbilities={{ action: 'page-view', subject: 'academic-plans' }}>
                        <Suspense fallback={<Spinner />}>
                            <AcademicEngineScreen />
                        </Suspense>
                    </AclGuard>
                )
            },
            {
                id: 'success-payment-screen',
                path: 'success_payment',
                element: (
                    <AclGuard aclAbilities={{ action: 'page-view', subject: 'success-payment' }}>
                        <Suspense fallback={<Spinner />}>
                            <SuccessPaymentScreen />
                        </Suspense>
                    </AclGuard>
                )
            },
            {
                id: 'failure-payment-screen',
                path: 'failed_payment',
                element: (
                    <AclGuard aclAbilities={{ action: 'page-view', subject: 'failed-payment' }}>
                        <Suspense fallback={<Spinner />}>
                            <FailedPaymentScreen />
                        </Suspense>
                    </AclGuard>
                )
            }
        ]
    },
    {
        id: 'payment-screens-group',
        path: 'payment',
        children: [
            {
                id: 'credits-success-payment',
                path: 'success',
                element: (
                    <AclGuard aclAbilities={{ action: 'page-view', subject: 'credits-success-payment' }}>
                        <Suspense fallback={<Spinner />}>
                            <CreditsSucessPaymentScreen />
                        </Suspense>
                    </AclGuard>
                )
            },
            {
                id: 'credits-failed-payment',
                path: 'failed',
                element: (
                    <AclGuard aclAbilities={{ action: 'page-view', subject: 'credits-failed-payment' }}>
                        <Suspense fallback={<Spinner />}>
                            <CreditsFailedPaymentScreen />
                        </Suspense>
                    </AclGuard>
                )
            }
        ]
    },
    {
        id: 'profile-screen',
        path: 'profile',
        element: (
            <AclGuard aclAbilities={{ action: 'page-view', subject: 'profile' }}>
                <Suspense fallback={<Spinner />}>
                    <ProfileScreen />
                </Suspense>
            </AclGuard>
        )
    },
    {
        id: 'api-management-screen',
        path: 'api',
        element: (
            <AclGuard aclAbilities={{ action: 'page-view', subject: 'api-management' }}>
                <Suspense fallback={<Spinner />}>
                    <ApiManagementScreen />
                </Suspense>
            </AclGuard>
        )
    },
    {
        id: 'prospect-engine-group',
        path: 'prospect',
        children: [
            {
                id: 'company-search-screen',
                path: 'company',
                element: (
                    <AclGuard aclAbilities={{ action: 'page-view', subject: 'company-search' }}>
                        <Suspense fallback={<Spinner />}>
                            <CompanySearchScreen />
                        </Suspense>
                    </AclGuard>
                )
            },
            {
                id: 'company-info-screen',
                path: 'company/:id',
                element: (
                    <AclGuard aclAbilities={{ action: 'page-view', subject: 'company-profile' }}>
                        <Suspense fallback={<Spinner />}>
                            <CompanyInfoScreen />
                        </Suspense>
                    </AclGuard>
                )
            },
            {
                id: 'contact-search-screen',
                path: 'contact',
                element: (
                    <AclGuard aclAbilities={{ action: 'page-view', subject: 'contact-search' }}>
                        <Suspense fallback={<Spinner />}>
                            <ContactSearchScreen />
                        </Suspense>
                    </AclGuard>
                )
            },
            {
                id: 'contact-info-screen',
                path: 'contact/:id',
                element: (
                    <AclGuard aclAbilities={{ action: 'page-view', subject: 'contact-profile' }}>
                        <Suspense fallback={<Spinner />}>
                            <ContactInfoScreen />
                        </Suspense>
                    </AclGuard>
                )
            },
            {
                id: 'corporation-info-screen',
                path: 'corporation/:id',
                element: (
                    <AclGuard aclAbilities={{ action: 'page-view', subject: 'corporation-profile' }}>
                        <Suspense fallback={<Spinner />}>
                            <CorporationInfoScreen />
                        </Suspense>
                    </AclGuard>
                )
            }
        ]
    },
    {
        id: 'teams-group',
        path: 'teams',
        children: [
            {
                id: 'teams-list-screen',
                index: true,
                element: (
                    <AclGuard aclAbilities={{ action: 'page-view', subject: 'teams' }}>
                        <Suspense fallback={<Spinner />}>
                            <TeamsListScreen />
                        </Suspense>
                    </AclGuard>
                )
            },
            {
                id: 'team-member-info-screen',
                path: 'member/:email',
                element: (
                    <AclGuard aclAbilities={{ action: 'page-view', subject: 'teams-member-info' }}>
                        <Suspense fallback={<Spinner />}>
                            <TeamMemberInfoScreen />
                        </Suspense>
                    </AclGuard>
                )
            }
        ]
    },
    {
        id: 'campaigns-group',
        path: 'campaigns',
        children: [
            {
                id: 'campaigns-list-screen',
                index: true,
                element: (
                    <AclGuard aclAbilities={{ action: 'page-view', subject: 'campaigns' }}>
                        <Suspense fallback={<Spinner />}>
                            <CampaignsListScreen />
                        </Suspense>
                    </AclGuard>
                )
            },
            {
                id: 'new-campaign-screen',
                path: 'new',
                element: (
                    <AclGuard aclAbilities={{ action: 'page-view', subject: 'new-campaign' }}>
                        <Suspense fallback={<Spinner />}>
                            <CampaignProvider>
                                <NewCampaignScreen />
                            </CampaignProvider>
                        </Suspense>
                    </AclGuard>
                )
            },
            {
                id: 'campaign-info-screen',
                path: ':id',
                element: (
                    <AclGuard aclAbilities={{ action: 'page-view', subject: 'campaign-info' }}>
                        <Suspense fallback={<Spinner />}>
                            <CampaignProvider>
                                <CampaignInfoScreen />
                            </CampaignProvider>
                        </Suspense>
                    </AclGuard>
                )
            },
            {
                id: 'campaigns-emails-management-group',
                path: 'emails',
                children: [
                    {
                        id: 'campaigns-emails-list-screen',
                        index: true,
                        element: (
                            <AclGuard aclAbilities={{ action: 'page-view', subject: 'campaigns-emails' }}>
                                <Suspense fallback={<Spinner />}>
                                    <CampaignsEmailsManagementScreen />
                                </Suspense>
                            </AclGuard>
                        )
                    },
                    {
                        id: 'new-campaign-email-screen',
                        path: 'new',
                        element: (
                            <AclGuard aclAbilities={{ action: 'page-view', subject: 'new-campaign-email' }}>
                                <Suspense fallback={<Spinner />}>
                                    <NewCampaignEmailScreen />
                                </Suspense>
                            </AclGuard>
                        )
                    },
                    {
                        id: 'edit-campaign-email-screen',
                        path: ':id',
                        element: (
                            <AclGuard aclAbilities={{ action: 'page-view', subject: 'new-campaign-email' }}>
                                <Suspense fallback={<Spinner />}>
                                    <EditCampaignEmailScreen />
                                </Suspense>
                            </AclGuard>
                        )
                    }
                ]
            }
        ]
    },
    {
        id: 'tickets-group',
        path: 'tickets',
        children: [
            {
                id: 'tickets-list-screen',
                index: true,
                element: (
                    <AclGuard aclAbilities={{ action: 'page-view', subject: 'tickets' }}>
                        <Suspense fallback={<Spinner />}>
                            <TicketsListScreen />
                        </Suspense>
                    </AclGuard>
                )
            },
            {
                id: 'new-ticket-screen',
                path: 'new',
                element: (
                    <AclGuard aclAbilities={{ action: 'page-view', subject: 'new-ticket' }}>
                        <Suspense fallback={<Spinner />}>
                            <NewTicketScreen />
                        </Suspense>
                    </AclGuard>
                )
            }
        ]
    }
]

export default panelRoutes

// ** react imports
import { Suspense, lazy } from 'react'

// ** react router imports
import { RouteObject } from 'react-router-dom'

// ** custom components imports
import Spinner from 'src/@core/components/spinner'

const SignUpScreen = lazy(() => import('../pages/auth/signup'))
const SignInScreen = lazy(() => import('../pages/auth/signin'))
const ForgetPasswordScreen = lazy(() => import('../pages/auth/forgot-password'))
const AcademicUserSignUpScreen = lazy(() => import('../pages/auth/academic-signup'))
const B2BUserSignUpScreen = lazy(() => import('../pages/auth/b2b-signup'))
const LinkedInVerificationScreen = lazy(() => import('../pages/auth/linkedin-verification'))

const authRoutes: Array<RouteObject> = [
    {
        id: 'normal-users-auth-routes-group',
        children: [
            {
                id: 'sing-up-screen',
                path: 'signup',
                element: (
                    <Suspense fallback={<Spinner />}>
                        <SignUpScreen />
                    </Suspense>
                )
            },
            {
                id: 'sing-in-screen',
                path: 'signin',
                element: (
                    <Suspense fallback={<Spinner />}>
                        <SignInScreen />
                    </Suspense>
                )
            },
            {
                id: 'forget-password-screen',
                path: 'forgot-password',
                element: (
                    <Suspense fallback={<Spinner />}>
                        <ForgetPasswordScreen />
                    </Suspense>
                )
            },
            {
                id: 'linkedin-verification-screen',
                path: 'linkedin_verification',
                element: (
                    <Suspense fallback={<Spinner />}>
                        <LinkedInVerificationScreen />
                    </Suspense>
                )
            }
        ]
    },
    {
        id: 'academic-users-auth-routes-group',
        path: 'academic',
        children: [
            {
                id: 'academic-sign-up-screen',
                path: 'signup',
                element: (
                    <Suspense fallback={<Spinner />}>
                        <AcademicUserSignUpScreen />
                    </Suspense>
                )
            }
        ]
    },
    {
        id: 'b2b-users-auth-routes-group',
        path: 'b2b',
        children: [
            {
                id: 'b2b-sign-up-screen',
                path: 'signup',
                element: (
                    <Suspense fallback={<Spinner />}>
                        <B2BUserSignUpScreen />
                    </Suspense>
                )
            }
        ]
    }
]

export default authRoutes

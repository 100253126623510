// ** react imports
import { FC, useEffect } from 'react'

// ** react router imports
import { Outlet, useLocation, useNavigate } from 'react-router-dom'

// ** mui imports
import { styled } from '@mui/material/styles'
import Stack, { StackProps } from '@mui/material/Stack'

// ** hooks imports
import { useSettings } from '../hooks/useSettings'

// ** third party imports
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import { GoogleOAuthProvider } from '@react-oauth/google'
import { useLocalStorage } from 'usehooks-ts'

// ** configs imports
import authConfig from 'src/configs/auth'

// Styled component for Blank Layout component
const BlankLayoutWrapper = styled(Stack)<StackProps>(({ theme }) => ({
    height: '100vh',

    // For V1 Blank layout pages
    '& .content-center': {
        display: 'flex',
        minHeight: '100vh',
        alignItems: 'center',
        justifyContent: 'center',
        padding: theme.spacing(5)
    },

    // For V2 Blank layout pages
    '& .content-right': {
        display: 'flex',
        minHeight: '100vh',
        overflowX: 'hidden',
        position: 'relative'
    }
}))

const AuthLayout: FC = () => {
    // ** vars
    const recaptchaSiteKey = process.env.REACT_APP_RECAPTCHA_SITE_KEY!
    const googleAuthClientID = process.env.REACT_APP_GOOGLE_AUTH_CLIENT_ID!

    // ** hooks
    const { settings, saveSettings } = useSettings()
    const location = useLocation()
    const navigate = useNavigate()
    const [storedToken] = useLocalStorage(authConfig.storageTokenKeyName, null)

    useEffect(() => {
        if (storedToken) navigate('/')
    }, [])

    useEffect(() => {
        if (settings.themeColor != 'primary') saveSettings({ themeColor: 'primary' })
    }, [location])

    return (
        <GoogleReCaptchaProvider reCaptchaKey={recaptchaSiteKey}>
            <GoogleOAuthProvider clientId={googleAuthClientID}>
                <BlankLayoutWrapper className='layout-wrapper' overflow='hidden'>
                    <Stack
                        className='app-content'
                        sx={{ minHeight: '100vh', overflowX: 'hidden', position: 'relative' }}
                    >
                        <Outlet />
                    </Stack>
                </BlankLayoutWrapper>
            </GoogleOAuthProvider>
        </GoogleReCaptchaProvider>
    )
}

export default AuthLayout

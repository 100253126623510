// ** casl imports
import { AbilityBuilder, Ability, RawRule } from '@casl/ability'

// ** models imports
import { UserRole, UserRoleType } from 'src/models'

export type Subjects = string
export type Actions = 'manage' | 'create' | 'read' | 'write' | 'update' | 'delete' | 'page-view' | 'menu'
export type ACLObjConditions = {
    role?: Array<UserRole>
    type?: string
}

export type AppAbility = Ability<[Actions, Subjects]> | undefined

export const AppAbility = Ability as any
export type ACLObj = {
    action: Actions
    subject: string
    filed?: string
    conditions?: ACLObjConditions
}

export const industrialUserRoles: Array<UserRole> = [
    'freelancer',
    'manager',
    'marketing specialist',
    'sales representative',
    'software engineer/developer'
]
export const academicUserRoles: Array<UserRole> = ['teacher', 'student']

/**
 * Please define your own Ability rules according to your app requirements.
 * We have just shown Admin and Client rules for demo purpose where
 * admin can manage everything and client can just visit ACL page
 */
const defineRulesFor = (role: string, newRules: Array<RawRule>) => {
    const { can, rules } = new AbilityBuilder(AppAbility)

    if (role == UserRoleType.INDUSTRIAL) {
        // all industrial user static rules
        const staticRules: Array<RawRule> = [
            // menus
            { action: ['menu'], subject: 'prospect-engine' },
            { action: ['menu'], subject: 'enrichment-engine' },
            { action: ['menu'], subject: 'industrial-business-directory' },
            { action: ['menu'], subject: 'hr-engine' },

            // engines
            { action: ['page-view'], subject: 'contact-search' },
            { action: ['page-view'], subject: 'contact-profile' },
            { action: ['page-view'], subject: 'corporation-profile' },
            { action: ['page-view'], subject: 'company-search' },
            { action: ['page-view'], subject: 'company-profile' },
            { action: ['page-view'], subject: 'services-list' },
            { action: ['page-view'], subject: 'new-bulk' },
            { action: ['page-view'], subject: 'services-history' },
            { action: ['page-view'], subject: 'service-result' },
            { action: ['page-view'], subject: 'business-directory' },
            { action: ['page-view'], subject: 'business-directory-orders' },
            // { action: ['page-view'], subject: 'hr-engine' },

            // subscriptions
            { action: ['page-view'], subject: 'lead-plans' },
            { action: ['page-view'], subject: 'enrichment-plans' },
            { action: ['page-view'], subject: 'success-payment' },
            { action: ['page-view'], subject: 'failed-payment' },
            { action: ['page-view'], subject: 'b2b-success-payment' },
            { action: ['page-view'], subject: 'b2b-failed-payment' },
            { action: ['page-view'], subject: 'credits-success-payment' },
            { action: ['page-view'], subject: 'credits-failed-payment' },

            // misc
            { action: ['page-view'], subject: 'teams' },
            { action: ['page-view'], subject: 'teams-member-info' },
            { action: ['page-view'], subject: 'campaigns' },
            { action: ['page-view'], subject: 'new-campaign' },
            { action: ['page-view'], subject: 'campaign-info' },
            { action: ['page-view'], subject: 'campaigns-emails' },
            { action: ['page-view'], subject: 'new-campaign-email' },
            { action: ['page-view'], subject: 'integrations' },
            { action: ['page-view'], subject: 'zapier-integration' },
            { action: ['page-view'], subject: 'hubspot-integration' },
            { action: ['page-view'], subject: 'hubspot-integration-successful-connect' },
            { action: ['page-view'], subject: 'hubspot-integration-failed-connect' },
            { action: ['page-view'], subject: 'zoho-integration' },
            { action: ['page-view'], subject: 'zoho-integration-successful-connect' },
            { action: ['page-view'], subject: 'salesforce-integration' },
            { action: ['page-view'], subject: 'salesforce-integration-successful-connect' },
            { action: ['page-view'], subject: 'credit-usage' },
            { action: ['page-view'], subject: 'lists' },
            { action: ['page-view'], subject: 'list-view' }
        ]

        staticRules.concat(newRules).forEach((rule) => {
            can(rule.action, rule.subject, rule.fields)
        })
    } else if (role == UserRoleType.ACADEMIC) {
        // all academic user static rules
        const staticRules: Array<RawRule> = [
            // menus
            { action: ['menu'], subject: 'professor-search' },
            { action: ['menu'], subject: 'university-search' },

            // engines
            { action: ['page-view'], subject: 'professor-search' },
            { action: ['page-view'], subject: 'professor-profile' },
            { action: ['page-view'], subject: 'university-search' },
            { action: ['page-view'], subject: 'university-profile' },

            // plans
            { action: ['page-view'], subject: 'academic-plans' },

            // misc
            { action: ['page-view'], subject: 'success-payment' },
            { action: ['page-view'], subject: 'failed-payment' },
            { action: ['page-view'], subject: 'credit-usage' },
            { action: ['page-view'], subject: 'lists' },
            { action: ['page-view'], subject: 'list-view' }
        ]

        staticRules.concat(newRules).forEach((rule) => {
            can(rule.action, rule.subject, rule.fields)
        })
    } else if (role == UserRoleType.B2B) {
        // all b2b user static rules
        const staticRules: Array<RawRule> = [
            // menus
            { action: ['menu'], subject: 'business-directory' },
            { action: ['menu'], subject: 'orders-list' },

            // engines
            { action: ['page-view'], subject: 'business-directory' },
            { action: ['page-view'], subject: 'business-directory-orders' },

            // payments
            { action: ['page-view'], subject: 'b2b-success-payment' },
            { action: ['page-view'], subject: 'b2b-failed-payment' }
        ]

        staticRules.concat(newRules).forEach((rule) => {
            can(rule.action, rule.subject, rule.fields)
        })
    }

    const publicRules: Array<RawRule> = [
        { action: ['page-view'], subject: 'invoices' },
        { action: ['page-view'], subject: 'invoice-view' },
        { action: ['page-view'], subject: 'tickets' },
        { action: ['page-view'], subject: 'new-ticket' },
        { action: ['page-view'], subject: 'profile' },
        { action: ['page-view'], subject: 'api-management' }
    ]

    publicRules.concat(newRules).forEach((rule) => {
        can(rule.action, rule.subject, rule.fields)
    })

    return rules
}

export const buildAbilityFor = (role: string, rule: Array<RawRule>): AppAbility => {
    return new AppAbility(defineRulesFor(role, rule), {
        // https://casl.js.org/v5/en/guide/subject-type-detection
        // @ts-ignore
        detectSubjectType: (object) => object!.type
    })
}

export const defaultACLObj: ACLObj = {
    action: 'manage',
    subject: 'all'
}

export default defineRulesFor
